import React, { useEffect, useRef} from "react"
import { FormattedMessage } from "react-intl"

//importing components
import SEO from "../components/seo"
import ContactScreen from "../components/Contact"

import Layout from "../components/layout/en"  // here language in EN

//import styledComponents
import * as S from "../components/StyledHome"

import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger";

const WorkPage = (props) => {
  const item = useRef(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      gsap.registerPlugin(ScrollTrigger);
    }

    gsap.from(item.current, {
      autoAlpha: 0,
      scale: 0.7,
      scrollTrigger: {
        trigger: item.current,
        start: "top bottom",
        end: "top 150",
        markers: false,
        scrub: true,
        anticipatePin: 1,
      },
    });
  });

  return (
    <Layout location={props.location} >
      <SEO title={"Work"}/>
      <S.TopBar>
        <S.LinkContainer>
          <S.StyledLink to="/en/#bio">BIO</S.StyledLink>
          <S.StyledLink to="/en/#work">WORK</S.StyledLink>
          <S.StyledLink to="/en/#contact">CONTACT</S.StyledLink>
        </S.LinkContainer>
      </S.TopBar>

      <S.Title>
        <p>
          Ecco il video della mia avventura verso Santiago de Compostela
          <br />
        </p>
      </S.Title>
      <S.VideoContainer>
        <S.StyledVideo src='https://tiberio-s3-milan.s3.eu-south-1.amazonaws.com/final_Santiago_video.mp4' autoPlay muted />
      </S.VideoContainer>


      <S.SectionTitle id="contact">CONTACT</S.SectionTitle>
      <ContactScreen />
    </Layout>
  )
}

export default WorkPage
